import React, { Fragment, useState } from "react"
import { QuestionMarkCircleIcon } from "@heroicons/react/outline"
import { Transition } from "@headlessui/react"

const Tooltip = ({ text, className }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <Fragment>
      <div
        className={`inline-block cursor-pointer relative ${
          className ? className : ""
        } `}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={() => setShowTooltip(prev => !prev)}
      >
        <QuestionMarkCircleIcon className="h-5 w-5 text-gray-500" />
        <Transition
          show={showTooltip}
          enter="transition-opacity duration-100"
          enterFrom="opacity-0 transform -translate-y-2"
          enterTo="opacity-100 transform translate-y-0"
          leave="transition-opacity duration-100"
          leaveFrom="opacity-100 transform translate-y-0"
          leaveTo="opacity-0 transform -translate-y-2"
          className="absolute z-10 p-2 bg-gray-800 text-white rounded-md shadow-lg"
          style={{
            bottom: "100%",
            left: "50%",
            marginLeft: "-120px",
            width: 240,
          }}
        >
          <div>{text}</div>
        </Transition>
      </div>
    </Fragment>
  )
}

export default Tooltip
