import React, { ExoticComponent, Fragment, ReactNode } from "react"
import Tooltip from "../../common/Tooltip"

interface InputProps {
  type?: string
  label?: string
  id?: string
  name?: string
  placeholder?: string
  required?: boolean
  autoComplete?: string
  inputMode?:
    | "search"
    | "text"
    | "email"
    | "tel"
    | "url"
    | "none"
    | "numeric"
    | "decimal"
  min?: string
  max?: string
  pattern?: string
  white?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  value?: string
  asFragment?: boolean
  noStyle?: boolean
  className?: string
  tooltip?: string
}

const Input: React.FC<InputProps> = ({
  type,
  label,
  id,
  name,
  placeholder,
  required,
  autoComplete,
  inputMode = "text",
  min,
  max,
  pattern,
  white,
  onChange,
  onKeyDown,
  value,
  asFragment,
  noStyle,
  className,
  tooltip,
}) => {
  let Tag: ExoticComponent<{ children?: ReactNode }> | string = "div"
  if (asFragment) {
    Tag = Fragment
  }

  return (
    <Tag>
      {label && (
        <label
          className={`block mb-2 text-sm font-medium  ${
            white ? "text-white" : "text-black"
          }`}
          htmlFor={id}
        >
          {label}
          {required && "*"}
          {tooltip && <Tooltip text={tooltip} className="ml-2" />}
        </label>
      )}
      <input
        className={`w-full p-4 leading-tight text-gray-700 appearance-none focus:outline-none  ${
          white ? "focus:ring-white" : "focus:ring-brand-yellow"
        } ${noStyle ? "border-0" : "border rounded shadow"}  ${
          className ? className : ""
        }`}
        id={id}
        name={name}
        placeholder={placeholder}
        type={type}
        required={required}
        min={min}
        max={max}
        pattern={pattern}
        autoComplete={autoComplete}
        inputMode={inputMode}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value}
      />
    </Tag>
  )
}

export default Input
